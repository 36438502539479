<template>
<div class="shop-clue-search">
  <!-- 校验数字和手机号 -->
  <search-bar-new
    ref="searchBar"
    :placeholder-text="$t('请输入完整的电话号码')"
    :str="searchStr"
    :showScreen="false"
    :showCategory="false"
    @input="onInput"
    @search="onSearch"/>
  <List ref="list" :fetchApi="fetchApi" :finishedText="totalCount>0?$t('没有更多了'):$t('暂无数据')">
    <template v-slot:default="{ item, index }">
      <SearchItem :dataSource="item" :userInfo="getUserInfo" 
        @receive="onReceive(item)"
        @authorize="onAuthorize(item)"
        @details="onDetails(item)"/>
    </template>
  </List>
</div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import List from '@/components/baseList/list.vue'
import loading from '@/utils/loading'
import clueServices from '@/services/clueServices'
import SearchItem from './search-item.vue'
import ClueDistributionServices from '@/services/clueDistributionServices'

const reg = new RegExp(/^[0-9]\d{10}$/)
export default {
  name: 'shopClueSearchList',
  components:{ SearchBarNew,List,SearchItem },
  data(){
    return {
      searchStr: '',
      inputValue: '',
      totalCount: 0
    }
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  beforeRouteEnter(to, from, next){
    if (from.name==='shopClueSearch'){
      next((vm) => {
        vm.$store.commit('app/addKeepAlive', 'shopClueSearchList')
        const { searchStr } = vm.$route.query
        vm.$refs.searchBar.searchStr = searchStr
        vm.inputValue = searchStr
        vm.searchStr = searchStr
        vm.$refs.list.onRefresh()
      })
    } else {
      next()
    }
  },
  methods:{
    onInput(value){
      this.inputValue = value
    },
    onSearch(){
      if (!this.inputValue){
        this.$router.go(-1)
      }
      if (this.inputValue && !reg.test(this.inputValue)){
        return this.$toast(this.$t('请输入完整的电话号码'))
      }
      this.searchStr = this.inputValue
      this.$refs.list.onRefresh()
    },
    // 获取列表数据
    fetchApi(obj={}) {
      if (!this.searchStr.trim()){
        this.totalCount = '0'
        return {
          dataList:[],
          page:{
            pageCount:'1',
            totalCount:'0',
            pageIndex:'1',
            pageSize: '20'
          }
        }
      } else {
        const params = {
          pageNum: obj.currentPage,
          pageSize: obj.pageSize,
          userMobile: this.searchStr
        }
        loading.showLoading()
        return clueServices.dealerClueListPost(params).then(res => {
          loading.hideLoading()
          this.totalCount = res.page.totalCount
          res.dataList.forEach(item=>item.checked=false)
          return res
        })
      }
    },
    // 领取
    onReceive(item) {
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: this.$t('确定领取该线索'),
        confirmButtonColor: '#B9921A',
        confirmButtonText: this.$t('立即领取'),
      }).then(() => {
        // 判断线索是否在公共池
        if (item.isRecycle===1){
          ClueDistributionServices.setReceiveRecycle({ id: item.id }).then(()=>{
            this.$toast(this.$t('领取成功'))
            setTimeout(() => {
              this.$refs.list.onRefresh()
            }, 1000)
          })
        } else {
          clueServices.receiveClue({ id: item.id }).then(()=>{
            this.$toast(this.$t('领取成功'))
            setTimeout(() => {
              this.$refs.list.onRefresh()
            }, 1000)
          })
        }
        
      })
    },
    // 申请授权
    onAuthorize(item){
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: `${this.$t('确定向【')}${item.followUserName}${this.$t('】申请客户接待授权？')}`,
        confirmButtonColor: '#B9921A',
      }).then(() => {
        clueServices.applyEmpowerPost({ id: item.id }).then(()=>{
          this.$toast(this.$t('申请成功'))
        })
      })
    },
    // 查看详情
    onDetails({ id }){
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
        },
      })
    }
  }
}
</script>
<style lang="less" scoped>
.shop-clue-search{
  display: block;
}
.flex-display{
  display: flex;
}
</style>
